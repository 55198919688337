// ==========================================================================
// Section Listing Molecule
// ==========================================================================

.m-section-listing {
  padding-bottom: spacing(medium);
  border-bottom: 1px solid palette("grey");
  margin-bottom: spacing(medium);

  @include respond-to(medium) {
    padding-bottom: spacing(large);
    margin-bottom: spacing(large);
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}

.m-section-listing__title {
  padding-bottom: spacing(0);
  margin-top: spacing(10) !important; // Stop this being overridden by first of type page-content modifier
  margin-bottom: spacing(5);
  font-size: ms(0);

  @include respond-to(medium) {
    font-size: ms(1, $thread: desktop);
  }
}

.m-section-listing__title + p {
  padding-top: 0;
  margin-top: 0;
}

.m-section-listing--short {
  .m-section-listing__title {
    margin: spacing(0 0 8 0);
    font-size: ms(0, $thread: desktop);

    @include respond-to(medium) {
      font-size: ms(0, $thread: desktop);
    }
  }
}

// .m-section-listing--long-grid {

// }

// .m-section-listing--short-grid {

// }
