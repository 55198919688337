
$colours-map: (
  /* Pure Black */
  'black': (rgb(0, 0, 0)),
  /* Pure White */
  'white': (rgb(255, 255, 255)),
  /* Grey: - Example Usage: Section divider background and dividing lines lines */
  'grey': (rgb(243, 243, 244)),
  /* Grey Dark - Example Usage: Header and footer background */
  'grey-dark': (rgb(51, 51, 51)),
  /* Primary blue: - Example Usage: Used as primary link colour and CTA quick edit bar */
  'primary': (rgb(23, 140, 255)),
  /* Secondary orange: - Example Usage: Case study label */
  'secondary': (rgb(255, 103, 0)),
  /* Success Green */
  'success': (rgb(13, 188, 55)),
  /* Warning Red */
  'warning': (rgb(214, 3, 3)),
);
