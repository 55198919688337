// ==========================================================================
// Nav Mobile Molecule
// ==========================================================================

.m-nav-mobile {
  display: none;
  padding: spacing(20 25);
  background: palette(primary);

  &.is--open {
    display: block;
  }
}

.m-nav-mobile__item {
  display: block;

  @include link() {
    padding: spacing(3 0);
    color: white;
    font-size: ms(0);
    font-weight: regular;
  }

  @include link-hover() {
    color: tint(white, "darken");
  }

  &.is--active {
    @include link() {
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.m-nav-mobile__seperator {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: none;
  margin: 12px 0;
  border-radius: 0;
}
