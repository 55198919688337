// ==========================================================================
// Headings Atom
// ==========================================================================

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  padding: 0;
  margin: 1em 0 0.5em;
  color: palette(black);
  font-weight: bold;
  line-height: 1.33;
}

h1,
.h1 {
  font-size: ms(3);

  @include respond-to(medium) {
    margin: 1em 0 0.25em;
    font-size: ms(3, $thread: desktop);
  }
}

h2,
.h2 {
  font-size: ms(3);

  @include respond-to(medium) {
    font-size: ms(3, $thread: desktop);
  }
}

h3,
.h3 {
  font-size: ms(2);

  @include respond-to(medium) {
    font-size: ms(2, $thread: desktop);
  }
}

h4,
.h4 {
  font-size: ms(1);

  @include respond-to(medium) {
    font-size: ms(1, $thread: desktop);
  }
}

h5,
.h5 {
  margin: 1em 0 0.1em;
  font-size: ms(0);

  @include respond-to(medium) {
    font-size: ms(0, $thread: desktop);
  }
}

// Remove spacing from first element in site-content as we don't want to do this in markdown land
.site-content {
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child {
    padding-top: 0;
    margin-top: 0;
  }
}


