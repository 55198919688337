// ==========================================================================
// Chevron Atom
// ==========================================================================

@mixin chevron {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: rem-calc(5) solid palette(primary);
  border-right: rem-calc(4) solid transparent;
  border-left: rem-calc(4) solid transparent;
}

.chevron {
  @include chevron;
}

.chevron--down {
}

.chevron--up {
  transform: rotate(180deg);
}

.chevron--left {
  transform: rotate(90deg);
}

.chevron--white {
  border-top: rem-calc(5) solid palette(white);
}

.chevron--secondary {
  border-top: rem-calc(5) solid palette(secondary);
}
