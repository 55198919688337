// ==========================================================================
// Footer Molecule
// ==========================================================================

.m-footer {
  padding-top: spacing(50);
  padding-bottom: spacing(50);
  margin-top: 2px;
  background: palette(grey-dark);

  @media print {
    display: none;
  }
}
